<template>
  <b-card>
    <b-table ref="sessionTable" class="position-relative" :items="sessionList" responsive :fields="tableColumns"
      show-empty empty-text="Satınalım Bulunamadı">
      <!-- <template #cell(title)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.title }}</span>
        </div>
      </template>
      <template #cell(ip)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ IPPARSER(data.item.ip) }}</span>
        </div>
      </template>
      <template #cell(actions)="data">
        <b-button variant="flat" class="text-danger" @click="disconnectSession(data.item._id)">
          <feather-icon icon="XIcon" size="18" />
        </b-button>
      </template> -->
      <template #cell(price)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ currencyParser(data.item.price)
            }}</span>
        </div>
      </template>
      <template #cell(paidPrice)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ currencyParser(data.item.paidPrice)
            }}</span>
        </div>
      </template>
      <template #cell(updatedAt)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ new Date(data.item.updatedAt).toLocaleString("tr-TR", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
            }}</span>
        </div>
      </template>
      <template #cell(status)="data">
        <div class="text-nowrap">
          <span v-if="data.item.status == 'success'">
            <FeatherIcon icon="CheckIcon" class="text-success" size="18" />
          </span>
          <span v-else-if="data.item.status == 'pending'">
            <FeatherIcon icon="ClockIcon" class="text-warning" size="18" />
          </span>
          <span v-else>
            <FeatherIcon icon="XIcon" class="text-danger" size="18" />
          </span>
        </div>
      </template>
      <div slot="empty" class="text-center">
        <span class="align-middle">{{ $t("No Records Found") }}</span>
      </div>
    </b-table>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BTable,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
const {
  axiosRoutes: { payment },
} = require("/G_CONFIG");

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currencyParser: new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
      }).format,

      tableColumns: [
        { key: "purchaseAmount", label: "Adet", sortable: true },
        { key: "price", label: "Fiyat", sortable: true },
        { key: "paidPrice", label: "Ödenen", sortable: true },
        // { key: "createdAt", label: "Oluşturulma Tarihi", sortable: true },
        { key: "updatedAt", label: "Tarih", sortable: true },
        { key: "status", label: "Durum", sortable: true },
      ],
      sessionList: [],
    };
  },
  created() {
    this.getPayments();
    // axios.get(sessionPath.get).then((res) => {
    //   this.sessionList = res.data;
    //   // console.log("object :>> ", this.sessionList);
    // });
  },
  methods: {
    // disconnectSession(id) {
    //   axios.delete(sessionPath.delete + "/" + id).then((res) => {
    //     this.getSessions();
    //     // console.log("object :>> ", this.sessionList);
    //   });
    // },
    getPayments() {
      axios.get(payment.getAll).then((res) => {
        //console.log("res.data :>> ", res.data);
        this.sessionList = res.data; //.filter((item) => item.status !== "timeout");

        // console.log("object :>> ", this.sessionList);
      });
    },
    // IPPARSER(ip) {
    //   // return ip.replace("::ffff:", "");
    //   return ip;
    // },
  },
};
</script>
